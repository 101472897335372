<template>
    <section v-if="investigation" class="investigation">
        <v-layout align-center column justify-center class="investigation__header text-center">
            <span class="investigation__header-image" :style="`background-image: url('${$options.filters.formatImage(investigation.backgroundImage, 800, 800, 1)}')`" />

            <div class="name-lines" :lang="possibleLangs[contentIndex]">
                <h1 class="name-line">{{ investigation.name.split(/\s+\/\s+/)[contentIndex] }}</h1>
            </div>
            <div class="cta-lines" :lang="possibleLangs[contentIndex]">
                <h2 class="cta-line">{{ investigation.cta.split(/\s+\/\s+/)[contentIndex] }}</h2>
            </div>

            <base-button v-if="$store.state.account.currentUser" :to="{name: 'posts.create', query: { investigation: investigation.id}}" large rounded color="primary-light" class="my-3">{{ $t('investigations.addSighting') }}</base-button>
            <!-- TODO: Redirect to posts.create with that query after sign-in: -->
            <auth-button v-else large rounded color="primary-light" class="my-3">{{ $t('investigations.logInToAddSighting') }}</auth-button>

            <div class="t-base">
                <div class="stats__sightings">
                    {{ $tc('investigations.sightingCount', investigation.postCount) }}
                </div>
            </div>
        </v-layout>

        <v-tabs :key="investigation.id" centered class="u-sticky-tabs">
            <v-tab id="investigation-detail-tab_investigations.detail.description" exact replace :to="{ name: 'investigations.detail.description', params: { id: investigation.id } }">{{ $t('investigations.details') }}</v-tab>
            <v-tab id="investigation-detail-tab_investigations.detail.posts" replace :to="{ name: 'investigations.detail.posts', params: { id: investigation.id } }">{{ $t('investigations.sightings') }}</v-tab>
            <!-- <v-tab v-if="investigation.regionId" id="investigation-detail-tab_investigations.detail.map" replace :to="{ name: 'investigations.detail.map', params: { id: investigation.id } }">{{ $t('investigations.map') }}</v-tab> -->
        </v-tabs>

        <v-container>
            <router-view role="tabpanel" :aria-labelledby="`investigation-detail-tab_${$route.name}`" />
        </v-container>
    </section>
    <span v-else>
        <loading-indicator />
    </span>
</template>

<script lang="ts">
import { Investigation } from '@/types';
import Vue from '@/vueTyped';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import AuthButton from '@/components/AuthButton.vue';

export default Vue.extend({
    name: 'InvestigationDetail',
    metaInfo(): any {
        return {
            title: this.investigation ? this.investigation.name : 'Investigations',
            meta: [
                {
                    vmid: 'description',
                    name: 'description',
                    content: this.investigation ? this.investigation.cta : '',
                },
                {
                    property: 'og:description',
                    content: this.investigation ? this.investigation.cta : '',
                    vmid: 'og:description'
                },
                {
                    property: 'twitter:description',
                    content: this.investigation ? this.investigation.cta : '',
                    vmid: 'twitter:description'
                },
            ],
        };
    },
    components: {
        LoadingIndicator,
        AuthButton,
    },
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            possibleLangs: ['en', 'es'],
        };
    },
    computed: {
        investigation(): Investigation {
            return this.$store.state.investigations.selectedItem;
        },
        contentIndex(): number {
            const translationsPresent = this.investigation.name.split(/\s+\/\s+/).length;

            if (translationsPresent > 1) {
                const index = this.possibleLangs.indexOf(this.$i18n.locale);

                if (index !== -1) {
                    return index;
                } else {
                    return 0;
                }
            } else {
                return 0;
            }
        }
    },
    mounted() {
        this.$store.dispatch('fetchAndSetInvestigation', { id: this.id });
    },
});
</script>
<style lang="postcss" scoped>
.investigation__header {
    color: var(--color-white);
    padding: var(--spacing-12) var(--spacing-4);
    position: relative;
    min-height: 50vh;
    text-shadow: 1px 1px rgba(0,0,0,0.5);
    > * {
        z-index: 1;
    }
}
:deep(.v-btn) {
    text-shadow: none;
}
.investigation__header-image {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 0;
    background-size: cover;
    background-position: center center;
    filter: blur(4px);
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        background-color: rgba(0,0,0,0.3);
    }
}
.name-lines {
    /* margin-bottom: 2rem; */
}

.cta-lines {
    margin: var(--spacing-8) 0;
}
</style>
