<template>
    <div class="sighting-preview-slider">
        <slippy-carousel prev-next-arrows fit-each-panel class="carousel" @input="$emit('input', $event)">
            <view-detector v-for="post in allPosts" :key="post.id" :post-id="post.id" class="sighting-preview">
                <div v-if="$scopedSlots.callout || $te(`actionsPage.postContent.${post.id}.callout`)" class="callout">
                    <slot name="callout" :post="post">
                        <template v-if="$te(`actionsPage.postContent.${post.id}.callout`)">
                            {{ $t(`actionsPage.postContent.${post.id}.callout`) }}
                        </template>
                    </slot>
                </div>

                <div v-if="post.photoObjs.length !== 0" :class="{ 'photo-aspect-ratio': !notSquare }">
                    <img :src="post.photoObjs[0] | formatImage(330)" alt="" class="photo">
                </div>

                <div v-for="(message, i) in messagesByPost[post.id]" :key="message.id" class="message">
                    <div class="byline">
                        <img v-if="message.userObj.avatar" :src="message.userObj.avatar | formatImage(20)" alt="" width="20" height="20" class="avatar">
                        <strong>{{ message.userObj.firstName }} {{ message.userObj.lastName }}</strong>
                    </div>

                    <div v-if="$te(`actionsPage.postContent.${post.id}.comments.${i}`)">
                        “{{ $t(`actionsPage.postContent.${post.id}.comments.${i}`) }}”
                    </div>

                    <div v-else-if="message.textBody">
                        “{{ message.textBody }}”
                    </div>

                    <div v-if="showMessageMetadata" class="metadata">
                        <span>{{ message.shortAddress }}</span>
                        <span>{{ message.createdAt | formatDate }}</span>
                    </div>
                </div>

                <div v-if="postCta" class="mb-2 text-center">
                    <!-- The click handler is required on this span for the parent listener to pick up on it. -->
                    <span @click.capture="$emit('clickcta', post)">
                        <base-button :to="{ name: 'posts.detail', params: { id: post.id } }" color="primary" class="mt-4">
                            {{ postCta }}
                        </base-button>
                    </span>
                </div>
            </view-detector>
        </slippy-carousel>
    </div>
</template>

<script lang="ts">
import Vue from '@/vueTyped';
import { Comment, Post } from '@/types';
import SlippyCarousel from '@/components/SlippyCarousel.vue';
import ViewDetector from '@/components/ViewDetector.vue';

interface MessageMap {
    [id: string]: (Post | Comment)[];
}

export default Vue.extend({
    name: 'SightingPreviewSlider',

    components: {
        SlippyCarousel,
        ViewDetector,
    },

    props: {
        notSquare: Boolean,

        posts: {
            type: Array as () => (Post | string)[], // Posts and post IDs
            default: () => [],
        },

        messages: {
            type: Number,
            default: 1,
        },

        showMessageMetadata: {
            type: Boolean,
            default: false,
        },

        postCta: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            loading: false,
            error: null as Error | null,
            allPosts: [] as Post[],
        };
    },

    computed: {
        messagesByPost(): MessageMap {
            const result: MessageMap = {};
            for (const post of this.allPosts) {
                result[post.id] = [post, ...post.commentObjs].slice(0, this.messages);
            }
            return result;
        },
    },

    watch: {
        posts: {
            deep: true,
            handler() {
                this.refresh();
            },
        },
    },

    mounted() {
        this.refresh();
    },

    methods: {
        async refresh() {
            this.loading = true;

            const fulfilledPosts = await Promise.all(this.posts.map(async id => {
                if (typeof id === 'string') {
                    const response = await this.$store.dispatch('fetchPostById', { id });
                    return response.data;
                } else {
                    return id;
                }
            }));

            this.allPosts = fulfilledPosts.filter(Boolean);

            if (this.allPosts.length === 0) {
                this.error = new Error('No posts fetched');
            }

            this.loading = false;
        },
    },
});
</script>

<style lang="postcss" scoped>
.sighting-preview-slider {
    position: relative;
}

.sighting-preview {
    border-radius: 5px 5px 0 0;
    overflow: hidden;
}

.callout {
    background: var(--color-tertiary);
    color: var(--color-secondary);
    font-weight: 500;
    padding: 0.4em 0.7em;
    width: 100%;
}

.carousel {
    --carousel-arrow-top: calc(50% - 2.5em);
    max-width: 400px;
    margin: 2em auto 0;
}

.photo-aspect-ratio {
    position: relative;
}

.photo-aspect-ratio::before {
    content: "";
    display: block;
    padding-top: 100%;
}

.photo-aspect-ratio > .photo {
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
}

.photo {
    border-radius: 0 0 5px 5px;
    width: 100%;
}

.message {
    margin-top: var(--spacing-4);
    word-break: break-word;
}

.byline {
    margin-bottom: var(--spacing-3);
}

.avatar {
    background: #8882;
    border-radius: 50%;
    height: 2.5em;
    margin-right: 1ch;
    object-fit: cover;
    object-position: top;
    vertical-align: middle;
    width: 2.5em;
}

.metadata {
    display: flex;
    font-style: italic;
    font-weight: bold;
    justify-content: space-between;
    margin-top: var(--spacing-1);
    opacity: 0.6;
}
</style>
