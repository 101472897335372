<template>
    <loading-indicator v-if="loading !== 0" />

    <v-container v-else :class="['large-container', { 'my-8': $vuetify.breakpoint.smAndUp }]" data-test-id="Actions page">
        <div :class="{ 'my-6': true, 'text-center': $vuetify.breakpoint.xsOnly }">
            <h1 class="t-subtitle">
                <template v-if="$route.query.welcome && currentUser">{{ $t('actionsPage.signUpIntroHeading', { name: currentUser.firstName }) }}</template>
                <template v-else>{{ $t('actionsPage.introHeading') }}</template>
            </h1>
            <p>{{ $t('actionsPage.intro') }}</p>
        </div>

        <v-expansion-panels ref="panels" v-model="openPanelIndex">
            <template v-for="[panelName, panelStrings] in partnerPanels">
                <v-expansion-panel :key="panelName" class="panel" :data-name="panelName">
                    <v-expansion-panel-header>
                        {{ panelStrings.headline }}
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                        <p>{{ panelStrings.body }}</p>

                        <p class="text-center">
                            <base-button :to="{ name: 'posts.create', query: { investigation: panelStrings.investigationId } }" color="primary" @click.native="trackCtaClick(panelName)">
                                {{ panelStrings.cta }}
                            </base-button>
                        </p>

                        <sighting-preview-slider :posts="Object.assign([], panelStrings.posts)" @input="trackPreviewScroll(panelName)" />
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </template>

            <template v-for="panelName in ['Prior years']">
                <v-expansion-panel v-if="priorYearsPosts.length !== 0" :key="panelName" class="panel" :data-name="panelName">
                    <v-expansion-panel-header>
                        {{ $t('actionsPage.priorYears.headline') }}
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                        <p>{{ $t('actionsPage.priorYears.body', { distance: distanceNearUser }) }}</p>

                        <sighting-preview-slider
                            :posts="priorYearsPosts"
                            :messages="1"
                            :post-cta="$t('actionsPage.priorYears.cta')"
                            @input="trackPreviewScroll(panelName)"
                            @clickcta="trackPreviewPostClick(panelName, $event)"
                        >
                            <template #callout="{ post }">
                                <div class="text-center">{{ post.observedAt | formatDate('MMMM D, YYYY') }}</div>
                            </template>
                        </sighting-preview-slider>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </template>

            <template v-for="panelName in [season.charAt(0).toUpperCase() + season.slice(1)]">
                <v-expansion-panel v-if="season" :key="panelName" class="panel" :data-name="panelName">
                    <v-expansion-panel-header>
                        {{ $t(`actionsPage.seasons.${season}.headline`) }}
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                        <p>{{ $t(`actionsPage.seasons.${season}.body`) }}</p>

                        <p class="text-center">
                            <base-button :to="{ name: 'posts.create', query: { investigation: $t(`actionsPage.seasons.${season}.investigationId`) } }" color="primary" @click.native="trackCtaClick(panelName)">
                                {{ $t(`actionsPage.seasons.${season}.cta`) }}
                            </base-button>
                        </p>

                        <sighting-preview-slider :posts="Object.assign([], $t(`actionsPage.seasons.${season}.posts`))" @input="trackPreviewScroll(panelName)" />
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </template>

            <template v-for="panelName in ['Trends']">
                <v-expansion-panel v-if="trendsPosts.length !== 0" :key="panelName" class="panel" :data-name="panelName">
                    <v-expansion-panel-header>
                        {{ $t('actionsPage.trends.headline') }}
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                        <p>{{ $t('actionsPage.trends.body') }}</p>

                        <sighting-preview-slider
                            not-square
                            :posts="trendsPosts"
                            :post-cta="$t('actionsPage.trends.cta')"
                            @input="trackPreviewScroll(panelName)"
                            @clickcta="trackPreviewPostClick(panelName, $event)"
                        />
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </template>

            <template v-for="panelName in ['Stories']">
                <v-expansion-panel :key="panelName" class="panel" :data-name="panelName">
                    <v-expansion-panel-header>
                        {{ $t('actionsPage.stories.headline') }}
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                        <markdown-output :value="$t('actionsPage.share.body')" />

                        <ul>
                            <li v-for="{ text, href } in Object.assign([], $t('actionsPage.stories.links'))" :key="text">
                                <a :href="href" @click="trackStoryClick(panelName)">{{ text }}</a>
                            </li>
                        </ul>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </template>

            <template v-for="panelName in ['Comment']">
                <v-expansion-panel v-if="postsWithRecentComments.length !== 0" :key="panelName" class="panel" :data-name="panelName">
                    <v-expansion-panel-header>
                        {{ $t('actionsPage.comment.headline') }}
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                        <i18n tag="p" path="actionsPage.comment.body">
                            <router-link :to="{ name: 'posts' }">{{ $t('actionsPage.comment.sightingsFeed') }}</router-link>
                        </i18n>

                        <sighting-preview-slider
                            :posts="postsWithRecentComments"
                            :messages="2"
                            show-message-metadata
                            :post-cta="$t('actionsPage.comment.cta')"
                            @input="trackPreviewScroll(panelName)"
                            @clickcta="trackPreviewPostClick(panelName, $event)"
                        />
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </template>

            <template v-for="panelName in ['Share']">
                <v-expansion-panel :key="panelName" class="panel" :data-name="panelName">
                    <v-expansion-panel-header>
                        {{ $t('actionsPage.share.headline') }}
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                        <markdown-output :value="$t('actionsPage.share.body')" />

                        <p class="mt-4 text-center">
                            <base-button color="primary" @click.native="share(panelName)">
                                {{ $t('actionsPage.share.cta') }}
                            </base-button>
                        </p>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </template>
      </v-expansion-panels>
    </v-container>
</template>

<script lang="ts">
import Vue from '@/vueTyped';
import SightingPreviewSlider from '@/components/SightingPreviewSlider.vue';
import { VExpansionPanels, VExpansionPanel, VExpansionPanelHeader, VExpansionPanelContent } from 'vuetify/lib';
import { CurrentUser, Post, PostParameters } from '@/types';
import orderBy from 'lodash/orderBy';
import { trackActionsPageEvent } from '@/tracking';
import MarkdownOutput from '@/components/MarkdownOutput.vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import { aggregatePostData, createQueryParams } from '@/util.posts';
import uniqBy from 'lodash/uniqBy';
import { ISEECHANGE_TRENDS_USER_ID } from '@/config';

// Using meteorological seasons from
// https://www.timeanddate.com/calendar/aboutseasons.html
function getSeason(date: Date, latitude: number): string {
    const MAR = 3;
    const JUN = 6;
    const SEP = 9;
    const DEC = 12;

    const month = date.getMonth() + 1; // Months are zero-indexed.
    const northern = latitude >= 0;

    // Let's consider the first half of March to be winter. It's still pretty cold.
    const isFirstHalfOfMarch = month === MAR && date.getDate() <= 15;

    if (month < MAR || month === DEC || isFirstHalfOfMarch) {
        return northern ? 'winter' : 'summer';
    } else if (month < JUN) {
        return northern ? 'spring' : 'autumn';
    } else if (month < SEP) {
        return northern ? 'summer' : 'winter';
    } else {
        return northern ? 'autumn' : 'spring';
    }
}

export default Vue.extend({
    name: 'ActionsPage',

    metaInfo: {
        title: 'Actions',
    },

    components: {
        SightingPreviewSlider,
        VExpansionPanels,
        VExpansionPanel,
        VExpansionPanelHeader,
        VExpansionPanelContent,
        MarkdownOutput,
        LoadingIndicator,
    },

    data() {
        return {
            loading: 0,
            openPanelIndex: 0, // Note: this will be unpredictable unless all the panels are loaded at the same time.
            priorYearsPosts: [] as Post[],
            trendsPosts: [] as Post[],
            postsWithRecentComments: [] as string[],
            today: typeof this.$route.query.today === 'string' ? new Date(this.$route.query.today) : new Date(),
        };
    },

    computed: {
        currentUser(): CurrentUser | null {
            return this.$store.state.account.currentUser;
        },

        distanceNearUser(): string {
            const NEARBY = { IMPERIAL: '50mi', METRIC: '80km' };
            const units = this.currentUser?.userSettings?.preferredSystemOfMeasure ?? 'IMPERIAL';
            return NEARBY[units];
        },

        partnerPanels(): [string, any][] {
            return Object.entries(this.$t('actionsPage.partners')).filter(panelNameAndTranslation => {
                const { investigationId, regionId } = panelNameAndTranslation[1];
                return this.$store.state.investigations.items.find(i => i.id === investigationId && i.regionId === regionId);
            });
        },

        season(): string {
            const latitude = this.currentUser?.lat;

            if (latitude) {
                return getSeason(this.today, latitude);
            } else {
                return '';
            }
        },
    },

    watch: {
        openPanelIndex(openPanelIndex: number) {
            // Note, the index is by order of presence, not DOM placement.
            const openPanel = (this.$refs.panels as Vue & { items: Vue[] }).items[openPanelIndex];
            if (openPanel?.$el instanceof HTMLElement && openPanel.$el.dataset.name) {
                trackActionsPageEvent('Opened Actions Page Panel', { 'Panel Name': openPanel.$el.dataset.name });
            }
        }
    },

    mounted() {
        trackActionsPageEvent('Viewed Actions Page');

        const pageRoute = this.$router.currentRoute;

        setTimeout(() => {
            if (this.$router.currentRoute === pageRoute) {
                this.$store.dispatch('updateSettings', {
                    lastViewedActionsPage: new Date().toISOString(),
                });
            }
        }, 3000);

        this.findPostsFromPriorYears();
        this.findTrendsPosts();
        this.findPostsWithRecentComments();
    },

    methods: {
        async findPostsFromPriorYears() {
            try {
                this.loading += 1;

                this.priorYearsPosts = [];

                if (this.currentUser) {
                    const yearsToFetch = [-1, -2, -3, -4, -5].map(ago => this.today.getFullYear() + ago);
                    const DAY_RANGE = 30;

                    const baseParams: PostParameters = {
                        limit: 5,
                        lngLat: [this.currentUser.lng, this.currentUser.lat],
                        distance: this.distanceNearUser,
                        withImage: true,
                    };

                    const postsByYears = await Promise.all(yearsToFetch.map(async year => {
                        const start = new Date(year, this.today.getMonth(), this.today.getDate() - Math.ceil(DAY_RANGE / 2));
                        const end = new Date(year, this.today.getMonth(), this.today.getDate() + Math.ceil(DAY_RANGE / 2));

                        const params: PostParameters = {
                            ...baseParams,
                            fromDate: start.toISOString(),
                            toDate: end.toISOString(),
                        };

                        const paramsString = createQueryParams(params);
                        const { data } = await this.$store.getters.apiClient.get(`/posts?${paramsString}`);
                        const posts = await aggregatePostData(this.$store, data.posts, data.photos, data.investigations, data.users, data.comments, data.weatherUnits);
                        // When adding `withImage`, the API returns duplicates.
                        return uniqBy(posts, 'id');
                    }));

                    this.priorYearsPosts = postsByYears.flat();
                }
            } catch (error) {
                console.error(error);
            } finally {
                this.loading -= 1;
            }
        },

        async findTrendsPosts() {
            try {
                this.loading += 1;
                const { data } = await this.$store.getters.apiClient.get(`/posts?user=${ISEECHANGE_TRENDS_USER_ID}&sort=observedAt+DESC&limit=5`);
                this.trendsPosts = await aggregatePostData(this.$store, data.posts, data.media, data.investigations, data.users, data.comments, data.weatherUnits);
            } catch (error) {
                console.error(error);
            } finally {
                this.loading -= 1;
            }
        },

        async findPostsWithRecentComments() {
            try {
                this.loading += 1;
                const { data } = await this.$store.getters.apiClient.get('/comments?limit=40&sort=createdAt+DESC');
                const releventPosts = (data.posts as Post[]).filter(post => post.photos.length !== 0 && post.comments.length >= 2);
                const sortedPosts = orderBy(releventPosts, 'createdAt', 'desc');
                this.postsWithRecentComments = sortedPosts.map(post => post.id).slice(0, 5);
            } catch (error) {
                console.error(error);
            } finally {
                this.loading -= 1;
            }
        },

        trackCtaClick(panelName: string) {
            trackActionsPageEvent('Clicked Actions Page CTA', { 'Panel Name': panelName });
        },

        trackPreviewScroll(panelName: string) {
            trackActionsPageEvent('Scrolled Actions Page Post Carousel', { 'Panel Name': panelName });
        },

        trackPreviewPostClick(panelName: string, post: Post) {
            trackActionsPageEvent(`Clicked Post from Actions Page Post Carousel`, { 'Panel Name': panelName, 'Post ID': post?.id });
        },

        trackStoryClick(panelName: string) {
            // Including the panel name is redundant, but we track this as a CTA.
            trackActionsPageEvent('Clicked Stories Link from Actions Page', { 'Panel Name': panelName });
        },

        share(panelName: string) {
            this.trackCtaClick(panelName);

            this.$store.dispatch('shareContent', {
                path: '/',
                title: 'ISeeChange | Community climate journal',
                text: 'Share your experiences and collect data to investigate our environment and help our communities change.',
            });
        },
    },
});
</script>

<style lang="postcss" scoped>
.panel[aria-expanded="true"] {
    padding-bottom: var(--spacing-2);
}

.panel > :deep(button) {
    color: var(--color-primary);
    position: relative;
    transition: margin 0.5s;
    margin-bottom: 0;
}

.panel > :deep(button::after) {
    background: var(--color-primary);
    content: "";
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity 0.5s;
    width: 100%;
}

.panel > :deep(button[class*="--active"]) {
    color: inherit;
    font-weight: bold;
    margin-bottom: 1em;
}

.panel > :deep(button[class*="--active"]::after) {
    opacity: 0.15;
}
</style>
