<template>
  <a :href="url" class="story-card pa-2" lang="en">
    <div class="story-card__inner" :style="backgroundStyle">
      <span class="story-card__content">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <h2 class="story-card__title t-subtitle" v-html="title" />
          <span class="story-card__date">{{ date | formatDate }}</span>
      </span>
    </div>
  </a>
</template>

<script lang="ts">
import Vue from '@/vueTyped';

export default Vue.extend({
  name: 'StoryCard',
  props: {
      imageUrl: {
        type: String,
        default: '',
      },
      title: {
        type: String,
        default: '',
      },
      url: {
        type: String,
        default: '',
      },
      author: {
        type: String,
        default: '',
      },
      date: {
        type: String,
        default: '',
      },
      imageAlign: {
        type: String,
        default: '',
      },
  },
  computed: {
    backgroundStyle(): {backgroundImage: string, backgroundSize: string} {
        let img = '';
        if (this.imageUrl) {
            if (this.imageUrl.includes('.png')) {
                img = this.imageUrl.split('.png')[0] + '-800x425.png';
            }
            if (this.imageUrl.includes('.jpg')) {
                img = this.imageUrl.split('.jpg')[0] + '-800x425.jpg';
            }
            if (this.imageUrl.includes('.jpeg')) {
                img = this.imageUrl.split('.jpeg')[0] + '-800x425.jpeg';
            }
        }
        return {
            backgroundImage: `url(${img})`,
            backgroundSize: this.imageAlign || 'center center',
        };
    },
  },
  methods: { },
});
</script>
<style lang="postcss" scoped>
.story-card {
    text-decoration: none;
    display: block;
}
.story-card__inner {
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    display: flex;
}
.story-card__title {

}
.story-card__content {
    width: 100%;
    padding: 5rem 2rem;
    background: rgba(0,0,0,0.35);
    display: flex;
    flex-direction: column;
    justify-content: middle;
}
.story-card__content > * {
    display: block;
    text-align: center;
    color: #ffffff;
    text-shadow: 0 0 0.15em rgba(0,0,0,1);
}
</style>
