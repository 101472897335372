<template>
    <div :class="['large-container', { 'my-8': $vuetify.breakpoint.smAndUp }]">
        <cms-content v-if="collectionUid" v-slot="nav" type="site_content_group" :uid="collectionUid">
            <div v-if="nav.document" :data-loading="nav.loading">
                <template v-if="nav.document.data.pages.length > 1">
                    <template v-if="$vuetify.breakpoint.smAndUp">
                        <prismic-rich-text
                            :field="nav.document.data.title"
                            class="t-title text-center"
                        />

                        <v-tabs v-if="$vuetify.breakpoint.smAndUp" centered class="navigation">
                            <v-tab
                                v-for="page in nav.document.data.pages"
                                :id="`cms-content-page-tab-for-${page.page.id}`"
                                :key="page.page.id"
                                :to="routeFor(nav.document, page.page)"
                                exact
                            >
                                {{ page.label }}
                            </v-tab>
                        </v-tabs>
                    </template>

                    <v-overflow-btn
                        v-else
                        :items="nav.document.data.pages"
                        :value="getActiveRoute(nav.document)"
                        item-text="label"
                        return-object
                        hide-details
                        class="overflow-menu my-0"
                        @change="$router.push(routeFor(nav.document, $event.page))"
                    >
                        <template #selection="{ item }">
                            <div class="contained-content">
                                <div class="overflow-menu-heading">
                                    <strong>Section</strong>
                                </div>
                                {{ item.label }}
                            </div>
                        </template>
                    </v-overflow-btn>
                </template>

                <!-- Load the first page by default. -->
                <cms-content-page :page-uid="pageUid || nav.document.data.pages[0].page.uid" hide-title />
            </div>

            <!-- Fall back to a page without a collection. -->
            <cms-content-page v-else-if="nav.notFound" :page-uid="collectionUid" />
        </cms-content>

        <cms-content
            v-else
            v-slot="page"
            type="site_content"
            :uid="pageUid"
            class="contained-content"
            @load="notFound === false; document = $event"
            @not-found="notFound = true"
        >
            <div v-if="page.document" :data-loading="page.loading" role="tabpanel" :aria-labelledby="`cms-content-page-tab-for-${page.document.id}`">
                <prismic-rich-text
                    v-if="!hideTitle"
                    :field="page.document.data.title"
                />

                <template v-for="(slice, i) in page.document.data.body">
                    <div v-if="slice.slice_type === 'content_block'" :key="i">
                        <prismic-rich-text
                            :field="slice.primary.content"
                            :data-introduction="slice.primary.introduction"
                            :data-alignment="slice.primary.align"
                        />
                    </div>

                    <v-row v-if="slice.slice_type === 'columns'" :key="i">
                        <v-col v-for="(item, j) in slice.items" :key="j" :cols="12 / slice.items.length">
                            <prismic-rich-text
                                :field="item.content"
                                :data-introduction="item.introduction"
                            />
                        </v-col>
                    </v-row>

                    <v-row v-else-if="slice.slice_type === 'leading_image'" :key="i">
                        <v-col cols="2">
                            <prismic-image :field="slice.primary.image" :data-round="slice.primary.round" />
                        </v-col>

                        <v-col>
                            <prismic-rich-text :field="slice.primary.content" />
                        </v-col>
                    </v-row>
                </template>
            </div>

            <four-oh-four v-else-if="page.notFound" />
        </cms-content>
    </div>
</template>

<script lang="ts">
import Vue from '@/vueTyped';
import CmsContent from '@/components/CmsContent.vue';
import FourOhFour from '@/pages/404.vue';
import { PrismicDocument } from '@/types';

export default Vue.extend({
    name: 'CmsContentPage',

    metaInfo(): any {
        return {
            title: this.document?.data.title[0].text ?? (this.notFound ? 'Not found' : ''),
        };
    },

    components: {
        CmsContent,
        FourOhFour,
    },

    props: {
        collectionUid: {
            type: String,
            default: '',
        },

        pageUid: {
            type: String,
            default: '',
        },

        hideTitle: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            document: null as PrismicDocument | null,
            notFound: false,
        };
    },

    methods: {
        routeFor(navDocument: PrismicDocument, page: { uid: string }) {
            const firstPage = navDocument.data.pages[0].page;
            return {
                name: 'cms_content_page',
                params: {
                    collection: navDocument.uid,
                    page: page.uid === firstPage.uid ? null : page.uid,
                },
            };
        },

        getActiveRoute(navDocument: PrismicDocument) {
            const currentParams = this.$router.currentRoute.params;
            return navDocument.data.pages.find((page: { page: { uid: string } }) => {
                const params = this.routeFor(navDocument, page.page).params;
                return params.collection === currentParams.collection && (params.page ?? null) === (currentParams.page ?? null);
            });
        },
    },
});

</script>
<style lang="postcss" scoped>
[data-loading] {
    opacity: 0.6;
}

[data-introduction] {
    font-size: 1.2em;
}

.contained-content {
    margin: var(--spacing-4);
}

.navigation {
    margin-top: -1em;
}

.overflow-menu :deep(.v-select__slot) {
    height: auto;
}

.overflow-menu-heading {
    font-size: 0.9em;
    text-transform: uppercase;
}

:deep(h2) {
    font-size: var(--type-subtitle);
    margin-bottom: 1em;
    text-align: center;
}

:deep(h3) {
    font-size: var(--type-lead);
}

:deep(h3:not(:first-child)) {
    margin-top: 3em;
}

:deep(img) {
    height: auto; /* Ignore height attribute to support the { max-width: 100% } set globally. */
}

:deep(.Call_to_action) {
    background: var(--color-primary, gray);
    border-radius: 2em;
    box-shadow: 0 0.2rem 0.3rem #0003;
    color: white;
    display: inline-block;
    font-weight: bold;
    padding: 0.3em 1.5em;
    text-decoration: none;
}

:deep(.Call_to_action a) {
    text-decoration: inherit;
}

[data-introduction] {
    color: var(--color-dark);
    font-size: var(--type-lead);
    margin: 2em 0;
}

[data-alignment="Center"] {
    text-align: center;
}

[data-alignment="End"] {
    text-align: end;
}

[data-round] {
    border-radius: 50vmax; /* A big radius to prevent making an ellipse */
}
</style>
