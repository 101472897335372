<template>
    <div v-if="loading" class="investigation-card">
        <v-card width="100%" class="elevation-0">
            <v-row no-gutters class="pa-4" align="center">
                <v-col sm="4">
                    <v-skeleton-loader type="image" />
                </v-col>
                <v-col sm="8" class="pl-4">
                    <v-skeleton-loader type="heading" class="mb-2" />
                    <v-skeleton-loader type="paragraph" />
                </v-col>
            </v-row>
        </v-card>
    </div>
    <router-link
        v-else
        :to="{ name: 'investigations.detail.description', params: { id: investigation.id } }"
        class="investigation-card"
        :class="{'is-featured': featured, 'is-highlighted': investigation.regionLabel}"
    >
        <v-card width="100%" height="100%" class="elevation-0" :class="{ 'card--featured': featured }">
            <v-row no-gutters align="center">
                <v-col cols="4" :class="{'investigation-image': true, 'featured-image': investigation.featured }">
                    <div class="tags">
                        <span v-if="featured && (!investigation.regionLabel || $vuetify.breakpoint.smAndUp)" class="tag">{{ $t('investigations.featured') }}</span>
                        <span v-if="investigation.regionLabel" class="tag">{{ investigation.regionLabel }}</span>&nbsp;
                    </div>
                    <async-image :image="investigation.backgroundImage" :height="300" :width="400" alt="" />
                </v-col>
                <v-col cols="8" class="investigation-card__content" lang="en">
                    <span v-if="investigation.regionLabel && $vuetify.breakpoint.smAndUp" class="investigation-card__regionLabel" aria-hidden="true">{{ investigation.regionLabel }}</span>
                    <h2 class="investigation-card__title">{{ investigation.name }}</h2>
                    <span class="investigation-card__description">{{ investigation.cta }}</span>
                </v-col>
            </v-row>
        </v-card>
    </router-link>
</template>

<script lang="ts">
import Vue from '@/vueTyped';
import { Investigation } from '@/types';
import AsyncImage from '@/components/AsyncImage.vue';

export default Vue.extend({
    name: 'InvestigationCard',
    components: {
        AsyncImage,
    },
    props: {
        investigation: {
            type: Object as () => Investigation,
            default: null,
        },
        featured: Boolean,
        loading: {
            type: Boolean,
            default: false,
        },
    },
});
</script>
<style lang="postcss" scoped>
.investigation-card {
    position: relative;
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-bottom: 2rem;

    &:hover .row {
        background-color: var(--color-light-tint);
    }
}
.investigation-card__title {
    color: var(--color-secondary);
    font-size: var(--type-lead);
    @media screen and (max-width: 768px) {
        font-size: var(--type-base);
    }
}
.tags {
    position: absolute;
    z-index: 1;
    top: var(--spacing-6);
    left: var(--spacing-6);
    :not(:first-child) {
        margin-left: var(--spacing-4);
    }
    @media screen and (max-width: 768px) {
        font-size: var(--type-small);
        top: var(--spacing-2);
        left: var(--spacing-2);
    }
}
.tag {
    border-radius: 0 !important;
    background-color: var(--color-danger-shade);
    box-decoration-break: clone;
    color: var(--color-danger-contrast);
    font-weight: bold;
    padding: 0.25rem 0.65rem;
    text-transform: uppercase;
}
.investigation-card__content {
    color: var(--color-dark);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    > * {
        padding-left: var(--spacing-4);
        padding-right: var(--spacing-4);
    }
}

.investigation-card__description {
    @media screen and (max-width: 768px) {
        line-height: 1.2;
        font-size: var(--type-small);
    }
}
.investigation-image {
    position: relative;
}
.featured-image {
    max-width: 360px;
}
.card--featured {
    padding-bottom: var(--spacing-6);

    .investigation-card__content {
        padding: var(--spacing-2);
    }
}
@media screen and (max-width: 1264px) {
    :deep(.v-skeleton-loader__image) {
        height: 140px;
    }
}
</style>
