<template>
  <div class="investigations">
    <v-container class="large-container">
        <cms-content v-slot="{ document }" :type="contentType">
            <section v-if="document" class="investigations-hero my-10">
                <h1 class="t-title text-center">
                    <prismic-rich-text :field="document.data.headline" />
                </h1>
                <p class="t-lead">
                    <prismic-rich-text :field="document.data.sub_headline" />
                </p>
                <p>
                    <prismic-rich-text :field="document.data.description" />
                </p>
            </section>
        </cms-content>
    </v-container>
    <v-container>
        <investigation-card
            v-for="investigation in featured"
            :key="investigation.id"
            class="featured-investigation"
            :featured="true"
            :investigation="investigation"
            :loading="!investigationsLoaded"
        />
        <section class="investigations-list">
            <investigation-card
                v-for="investigation in nonFeatured"
                :key="investigation.id"
                :investigation="investigation"
                :loading="!investigationsLoaded"
            />
        </section>
    </v-container>
  </div>
</template>

<script lang="ts">
import { Investigation, CONTENT_ID } from '@/types';
import Vue from '@/vueTyped';
import CmsContent from '@/components/CmsContent.vue';
import InvestigationCard from '@/components/InvestigationCard.vue';

export default Vue.extend({
    name: 'Investigations',
    metaInfo: {
        title: 'Investigations',
        meta: [
            {
                vmid: 'description',
                name: 'description',
                content: 'Pick an investigation that our partners are actively researching and add sightings from your own backyard, neighborhood or city.',
            },
            {
                property: 'og:description',
                content: 'Pick an investigation that our partners are actively researching and add sightings from your own backyard, neighborhood or city.',
                vmid: 'og:description'
            },
            {
                property: 'twitter:description',
                content: 'Pick an investigation that our partners are actively researching and add sightings from your own backyard, neighborhood or city.',
                vmid: 'twitter:description'
            },
        ],
    },
    components: {
        CmsContent,
        InvestigationCard,
    },
    data() {
        return {
            contentType: CONTENT_ID.INVESTIGATION_INDEX,
        };
    },
    computed: {
        investigations(): Investigation[] {
            return this.$store.state.investigations.items;
        },
        investigationsLoaded(): boolean {
            return this.$store.state.investigations.asyncStatus === 'success';
        },
        featured(): Investigation[] {
            const localFeatured = this.investigations.filter(investigation => investigation.featured && investigation.regionId);
            const nonlocalFeatured = this.investigations.filter(investigation => investigation.featured && !investigation.regionId);
            return localFeatured.concat(nonlocalFeatured);
        },
        nonFeatured(): Investigation[] {
            return this.investigations.filter((investigation: Investigation) => {
                return !this.featured.includes(investigation);
            });
        },
    },
    created() {
        this.$store.dispatch('fetchInvestigations');
    },
});
</script>
<style lang="postcss" scoped>
.investigations {
    --investigation-card-width: 100%;
    margin: 0 auto;
    padding: 0 1rem;
}
.investigations-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
@media screen and (min-width: 955px) {
    .investigations {
        --investigation-card-width: calc(50% - 1rem);
    }
}

.investigations-list :deep(.investigation-card) {
    width: var(--investigation-card-width);
}
@media screen and (min-width: 768px) {
    .featured-investigation :deep(.investigation-card__title) {
        font-size: var(--type-title);
    }
    .featured-investigation :deep(.investigation-card__description) {
        font-size: var(--type-lead);
    }

    .featured-investigation :deep(.investigation-card__content) {
        padding: var(--spacing-4) var(--spacing-3) var(--spacing-4) var(--spacing-3);
    }
}
@media screen and (max-width: 768px) {
    :deep(.container) {
        padding: 0;
    }
}


</style>
