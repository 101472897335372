<template>
    <!-- TODO add v-lazy -->
    <div class="async-image-wrapper" :style="{'--aspect-ratio': requestDimensions.width / requestDimensions.height, maxHeight: `${requestDimensions.height}px` }">
        <img ref="img" :src="url" :alt="$attrs.alt" class="async-image" :data-crop="!!(width && height)">
        <span class="crop-icon" hidden>
            <v-icon v-if="cropDirection" color="white" :aria-label="`Cropped ${cropDirection} image`">panorama_{{ cropDirection }}</v-icon>
        </span>
    </div>
</template>

<script lang="ts">
import Vue from '@/vueTyped';
import { Photo } from '@/types';
import { BASE_API_URL } from '@/config';

export default Vue.extend({
    name: 'AsyncImage',
    props: {
        image: {
            type: Object as () => Photo,
            default: () => ({} as Photo),
        },
        width: {
            type: Number,
            default: 0,
        },
        height: {
            type: Number,
            default: 0,
        },
        cropIconThreshold: {
            type: Number,
            default: Infinity,
        },
    },
    computed: {
        requestDimensions(): { width: number, height: number } {
            let width = this.width;
            let height = this.height;
            if (!width && !height) {
                // TODO: We could pick something out based on the viewport's and image's sizes and orientations.
                throw new Error('AsyncImage requires at least a width or a height');
            }
            if (!width) {
                width = this.image.width / (this.image.height / this.height);
            }
            if (!height) {
                height = this.image.height / (this.image.width / this.width);
            }
            return { width, height };
        },
        url(): string {
            if (!this.image.dirPath) {
                return '';
            }
            const { width, height } = this.requestDimensions;
            return `${BASE_API_URL}/${this.image.dirPath}/w_${width},h_${height},c_fill,a_exif/${this.image.id}.${this.image.format}`;
        },
        cropDirection(): null | 'horizontal' | 'vertical' {
            let imageAspectRatio = this.image.width / this.image.height;
            const displayedAspectRatio = this.width / this.height;
            const ratioDiff = imageAspectRatio - displayedAspectRatio;
            if (Math.abs(ratioDiff) > this.cropIconThreshold) {
                return ratioDiff > 0 ? 'horizontal' : 'vertical';
            } else {
                return null;
            }
        }
    },
});
</script>

<style lang="postcss" scoped>
.async-image-wrapper {
    background-color: var(--color-light-tint);
    position: relative;
}
.async-image-wrapper::before {
    content: "";
    display: block;
    padding-top: calc(100% / var(--aspect-ratio));
}
.async-image {
    height: 100%;
    left: 0;
    object-fit: contain;
    position: absolute;
    top: 0;
    width: 100%;
}

.async-image[data-crop] {
    object-fit: cover;
}

.crop-icon {
    bottom: 0;
    left: 0;
    margin: 1rem;
    opacity: 0.5;
    position: absolute;
    text-shadow: 1.5px 1.5px black;
}
</style>
