<template>
    <!--
        TODO: Figure out how to get a reasonable `lang` attribute here.
        Can we mark the Spanish on the back end somehow?
    -->
    <div v-if="investigation" class="large-container" lang="en">
        <h4 class="mt-3">{{ investigation.issueHeader }}</h4>
        <div>{{ investigation.issueDescription }}</div>
        <h4>{{ investigation.actionHeader }}</h4>
        <div>{{ investigation.actionDescription }}</div>
        <markdown-output
            v-if="tweakedActionDescription"
            :value="tweakedActionDescription"
            trusted
            class="action-description-from-markdown"
        />
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Investigation, RootState } from '@/types';
import MarkdownOutput from '@/components/MarkdownOutput.vue';

export default Vue.extend({
    components: { MarkdownOutput },

    computed: {
        investigation(): Investigation {
            return (this.$store.state as RootState).investigations.selectedItem;
        },

        tweakedActionDescription(): string | null {
            if (this.investigation.actionDescriptionMarkdown) {
                // This is stored in the database, it's easier to change this here than on the back end.
                // We'll take care of this properly when this content makes its way to a CMS.
                return this.investigation.actionDescriptionMarkdown.replace(
                    'https://www.iseechange.org/img/partners/nola-ready.jpeg',
                    'https://images.prismic.io/iseechange/06cf588d-bed2-425b-96ab-a59bc461aa64_NOLA+Ready+Logo.png?auto=compress,format&h=150'
                ).replace(
                    'src="https://www.youtube.com/embed',
                    'title="Embedded YouTube video" src="https://www.youtube.com/embed'
                );
            } else {
                return null;
            }
        },
    },
});
</script>

<style lang="postcss" scoped>
h4,
.action-description-from-markdown :deep(h2),
.action-description-from-markdown :deep(h3),
.action-description-from-markdown :deep(h4) {
    /* There doesn't seem to be much structure to headings. */
    font-size: var(--type-subtitle);
    margin-top: 1.5em;
}

.action-description-from-markdown :deep(iframe) {
    max-width: 100%;

    @supports (aspect-ratio: 16/9) {
        aspect-ratio: 16/9;
        height: auto;
    }
}
.action-description-from-markdown :deep(img) {
    /* Reverse warping from inline styles. */
    box-sizing: content-box;
    height: auto;
}

.action-description-from-markdown :deep([style*="display:flex"]),
.action-description-from-markdown :deep([style*="display: flex"]) {
    flex-wrap: wrap;
}
</style>
