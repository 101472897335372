<template>
    <div class="stories px-4">
        <h1 v-if="$vuetify.breakpoint.smAndUp" class="t-subtitle text-center px-2 py-10">
            {{ $t('stories.headline') }}
        </h1>

        <template v-if="stories.length !== 0">
            <section class="stories-list my-4">
                <div class="stories-list__top">
                    <story-card
                        v-if="primaryStory"
                        :key="primaryStory.id"
                        class="story__featured"
                        :title="primaryStory.title"
                        :url="primaryStory.url"
                        :author="primaryStory.author"
                        :date="primaryStory.date"
                        :image-url="primaryStory.image || ''"
                        :image-align="primaryStory.image_align"
                    />

                    <div class="stories-list__secondary">
                        <story-card
                            v-for="(s) in secondaryStories"
                            :key="s.id"
                            class="story__secondary"
                            :title="s.title"
                            :url="s.url"
                            :author="s.author"
                            :date="s.date"
                            :image-url="s.image || ''"
                            :image-align="s.image_align"
                        />
                    </div>
                </div>

                <div class="stories-list__all">
                    <story-card
                        v-for="(s) in allOtherStories"
                        :key="s.id"
                        :title="s.title"
                        :url="s.url"
                        :author="s.author"
                        :date="s.date"
                        :image-url="s.image || ''"
                        :image-align="s.image_align"
                    />
                </div>
            </section>

            <p class="text-center my-12">
                <base-button large color="primary" :loading="$store.getters.storiesRequested" @click="loadMoreStories">
                    {{ $t('actions.showMore') }}
                </base-button>
            </p>
        </template>

        <loading-indicator v-else />
    </div>
</template>

<script lang="ts">
import { Story } from '@/types';
import Vue from '@/vueTyped';
import StoryCard from '@/components/StoryCard.vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';

export default Vue.extend({
    metaInfo: {
        title: 'Stories',
    },

    components: {
        StoryCard,
        LoadingIndicator,
    },

    computed: {
        stories(): Story[] {
            return this.$store.getters.storiesByDate;
        },

        primaryStory(): Story {
            return this.stories[0];
        },

        secondaryStories(): Story[] {
            return this.stories.slice(1, 3);
        },

        allOtherStories(): Story[] {
            return this.stories.slice(3);
        },
    },

    async mounted() {
        await this.$store.dispatch('fetchStories');
        // await this.loadMoreStories();
    },

    methods: {
        async loadMoreStories() {
            this.$store.dispatch('fetchStories', true);
        }
    },
});
</script>

<style lang="postcss" scoped>
.stories-list__all {
    display: flex;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;

    :deep(.story-card) {
        width: 100%;
        @media screen and (min-width: 768px) {
            width: 50%;
            min-height: 25vw;
        }
        @media screen and (min-width: 1200px) {
                width: 33.333%;
                min-height: 15vw;
        }
    }
}
.stories-list__top {
    @media screen and (min-width: 768px) {
        display: flex;
        flex-wrap: wrap;
        margin: 0 auto;
        > :deep(.story-card) {
            width: 100%;
        }
        :deep(.story-card__content) {
            justify-content: flex-end;
        }
        .stories-list__secondary {
            display: flex;
        }
    }
    @media screen and (min-width: 1200px) {
        display: flex;
        max-width: 1200px;
        margin: 0 auto;
        > :deep(.story-card) {
            width: 66.666%;
        }
        :deep(.story-card__content) {
            justify-content: flex-end;
        }
        .stories-list__secondary {
            display: flex;
            flex-direction: column;
            width: 33.333%;
        }
    }
}
</style>
